import React from "react";

export default function ImageButton({ onClick, value }) {
  const image =
    "https://1.ooz1es.com/oozies/ooz1es_" +
    (value + 1).toString().padStart(4, "0") +
    ".png";
  const token = "OOZ1ES#" + (value + 1);
  return (
    <button className="imgButton" onClick={onClick}>
      <img src={image} alt={"img" + value} className="oozie" />
      <strong style={{ fontSize: 32 }}>{token}</strong>
    </button>
  );
}
