import React, { useState } from "react";
import { Button, Icon, Modal } from "semantic-ui-react";
import Clipboard from "react-clipboard.js";

const prices = [
  {
    key: 1,
    total: "4 ₳ADA",
  },
  {
    key: 2,
    total: "8 ₳ADA",
  },
  {
    key: 3,
    total: "12 ₳ADA",
  },
  {
    key: 4,
    total: "16 ₳ADA",
  },
  {
    key: 5,
    total: "20 ₳ADA",
  },
  {
    key: 6,
    total: "24 ₳ADA",
  },
  {
    key: 7,
    total: "28 ₳ADA",
  },
  {
    key: 8,
    total: "32 ₳ADA",
  },
  {
    key: 9,
    total: "36 ₳ADA",
  },
  {
    key: 10,
    total: "40 ₳ADA",
  },
];

export default function BuyModal({ open, onClose }) {
  const [copied, setCopied] = useState(false);
  return (
    <Modal size="mini" open={open} dimmer="blurring" onClose={onClose}>
      <Modal.Content
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <h1 style={{ color: "#22ba1a", fontSize: 46 }}>8918 OOZ1ES Left!</h1>
        <Button
          positive
          icon
          labelPosition="right"
          onClick={() => setCopied(true)}
          as={Clipboard}
          data-clipboard-text="addr1vxztz3m8lemx4xqju397esqzuq5wv33xkawwrx0ulr3957svvdqtm"
          title="Click to Copy"
          style={{ wordBreak: "break-word" }}
        >
          addr1vxztz3m8lemx4xqju397esqzuq5wv33xkawwrx0ulr3957svvdqtm
          <Icon name={copied ? "check" : "copy"} />
        </Button>
        <p style={{ margin: 10 }}>
          Please send<span className="pink"> 4 ₳ADA </span>to the address above
          to receive<span className="pink"> 1 </span>OOZ1ES, to receive more
          OOZ1ES please send the amount listed on the chart below.
        </p>
        <table className="price-table">
          <thead>
            <tr>
              <th>Qty.</th>
              <th>Price</th>
            </tr>
          </thead>
          <tbody>
            {prices.map((price) => (
              <tr
                key={price.key}
                style={{ color: price.key === 10 ? "#ff10f0" : "" }}
              >
                <td>{price.key}</td>
                <td>{price.total}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Modal.Content>
    </Modal>
  );
}
