import React from "react";

const imIds = ["one", "two", "three", "four", "five", "six", "seven", "eight"];

export default function Rotate3D() {
  return (
    <div id="wrapper" overflow-y="scroll" overscroll-behavior-y="none">
      <div id="image">
        {imIds.map((id, idx) => (
          <div key={idx} id={id} className={`image i${idx + 1}`}>
            <img
              src={`https://1.ooz1es.com/oozies/ooz1es_000${idx + 1}.png`}
              width="200px"
              height="200px"
              alt="oozies"
            />
          </div>
        ))}
      </div>
    </div>
  );
}
