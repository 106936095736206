import React, { useEffect, useRef, useState } from "react";
import { Dropdown } from "semantic-ui-react";
import faces from "../data/faces.json";

const r = {
  u: "All Uncommon",
  r: "All Rare",
  e: "All Epic",
  l: "All Legendary",
};

const FaceRarity = ({ rarity, onRarityChanged }) => {
  let idx = 1;
  const [text, setText] = useState("");
  const dd = useRef();
  useEffect(() => {
    if (rarity === "") setText("");
  }, [rarity]);
  function itemClick(e, v) {
    setText(v.text);
    dd.current.handleItemClick(e, v);
  }
  return (
    <Dropdown
      ref={dd}
      placeholder="Face"
      fluid
      clearable
      style={{ marginBottom: 15 }}
      className="selection"
      value={rarity}
      text={text}
      onChange={onRarityChanged}
    >
      <Dropdown.Menu>
        {faces.map((text) =>
          text.length === 1 ? (
            <Dropdown.Item
              active={rarity === text}
              text={r[text]}
              key={text}
              value={text}
              onClick={itemClick}
              className="d-h"
            />
          ) : (
            <Dropdown.Item
              active={rarity === idx}
              text={text}
              key={idx}
              value={idx++}
              onClick={itemClick}
            />
          )
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default FaceRarity;
