import React from "react";
import { Modal, Image } from "semantic-ui-react";

export default function PreviewModal({ openedImage, onImageModalClose }) {
  const image =
    "https://1.ooz1es.com/oozies/ooz1es_" +
    (openedImage?.key + 1).toString().padStart(4, "0") +
    ".png";
  const label = `OOZ1ES#${openedImage?.key + 1}`;
  const colors = openedImage?.body?.split(/, /);
  return (
    <Modal
      size="mini"
      open={openedImage !== null}
      dimmer="blurring"
      onClose={onImageModalClose}
    >
      <Modal.Content
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <h1 className="m-header">{label}</h1>
        <div className="oozie-container">
          <Image
            src={image}
            alt={label}
            layout="fixed"
            style={{
              width: 320,
              height: 320,
              borderRadius: 10,
            }}
          />
          <div className="oozie-mask" />
        </div>
        <div className="r-badge red">Rank: {openedImage?.rank}</div>
        <ul className="m-details">
          <li>
            Body color:{" "}
            <span
              className="cl"
              dangerouslySetInnerHTML={{
                __html: colors?.map(
                  (c) => `<span class="${c.toLowerCase()}">${c}</span>`
                ),
              }}
            />
          </li>
          <li>Hat: {openedImage?.hat}</li>
          <li>Face: {openedImage?.face}</li>
        </ul>
      </Modal.Content>
    </Modal>
  );
}
