import React from "react";
import { TransitionGroup, Transition, Icon } from "semantic-ui-react";

export default function ExternalLinks() {
  const [open, setOpen] = React.useState(false);
  const [visible, setVisible] = React.useState(false);
  function handleClick() {
    setOpen(!open);
    setVisible(!visible);
  }
  return (
    <div className={`ext-links${visible ? " opened" : ""}`}>
      <button onClick={handleClick}>
        <h1 className="section-header">External Links</h1>
        <div className="trw">
          <Transition
            visible={visible}
            animation="vertical flip"
            duration={200}
          >
            <Icon style={{ color: "#22ba1a" }} name="angle up" size="large" />
          </Transition>
        </div>
        <div className="trw">
          <Transition
            visible={!visible}
            animation="vertical flip"
            duration={200}
          >
            <Icon color="white" name="angle down" size="large" />
          </Transition>
        </div>
      </button>
      <TransitionGroup animation="slide down" duration={500}>
        {open && (
          <ul>
            <li>
              We are now Verified on&mdash;
              <ul>
                <li>
                  <a
                    href="https://cnft.io/marketplace?st=ooz1es"
                    target="_blank"
                    rel="noreferrer"
                  >
                    CNFT.io
                  </a>
                </li>
                <li>
                  <a
                    href="https://nftjam.io/ooz1es"
                    target="_blank"
                    rel="noreferrer"
                  >
                    NFTJam.io
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.genesishouse.io/collections/1491"
                    target="_blank"
                    rel="noreferrer"
                  >
                    GenesisHouse.io
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        )}
      </TransitionGroup>
    </div>
  );
}
