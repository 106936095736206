import React, { useState, useRef, useEffect } from "react";
import { Dropdown } from "semantic-ui-react";
import colors from "../data/colors.json";

const BodyColorRarity = ({ rarity, onRarityChanged }) => {
  const [text, setText] = useState("");
  const dd = useRef();
  useEffect(() => {
    if (rarity === "") setText("");
  }, [rarity]);
  function itemClick(e, v) {
    setText(v.text);
    dd.current.handleItemClick(e, v);
  }
  return (
    <Dropdown
      ref={dd}
      placeholder="Body Color"
      fluid
      clearable
      style={{ marginBottom: 15 }}
      className="selection"
      onChange={onRarityChanged}
      value={rarity}
      text={text}
    >
      <Dropdown.Menu>
        {colors.map((item) =>
          "lure".includes(item.value) ? (
            <Dropdown.Item
              selected={rarity === item.value}
              className="d-h"
              {...item}
              onClick={itemClick}
            />
          ) : (
            <Dropdown.Item
              selected={rarity === item.value}
              icon={{
                name: "square",
                className: item.text
                  .toLowerCase()
                  .replace(/, /g, "")
                  .replace(/ /g, ""),
              }}
              {...item}
              onClick={itemClick}
            />
          )
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default BodyColorRarity;
