import "@splidejs/splide/dist/css/splide.min.css";
import React, { useState, useEffect } from "react";
import Splide from "@splidejs/splide";
import { Transition, Icon, Container, Button } from "semantic-ui-react";

const thermometers = [
  "/ft/thermometer_0.png",
  "/ft/thermometer_1.png",
  "/ft/thermometer_2.png",
  "/ft/thermometer_3.png",
  "/ft/thermometer_4.png",
  "/ft/thermometer_5.png",
  "/ft/thermometer_6.png",
  "/ft/thermometer_7.png",
  "/ft/thermometer_8.png",
  "/ft/thermometer_9.png",
  "/ft/thermometer_10.png",
];

const data = [
  [
    "Develop website, add searchable filters.",
    "Create rarity chart",
    "Build OOZ1ES community",
  ],
  [
    "Mix & Match tool (make your favorite Ooz1es & download as PNG)",
    "Add OOZ1ES to Anti.biz lore",
  ],
  [
    "Airdrop Series #1 3D digital toy to 30 random OOZ1ES hodlers as a unique limited edition NFT. Max supply 30. (anyone that purchases package of 10 will automatically be entered.)",
  ],
  [
    "Hire artist to draw issue #1 of OOZ1ES comicbook. Print 1,000-2,000 copies, put digital copy of comicbook on website as a free digital download.",
  ],
  [
    "Airdrop Series #2 3D digital toy to 30 random OOZ1ES hodlers as a unique limited edition NFT. Max supply 30. (anyone that purchases package of 10 will automatically be entered.)",
  ],
  [
    "Airdrop Series #3 3D digital toy to 30 random OOZ1ES hodlers as a unique limited edition NFT. Max supply 30. (anyone that purchases package of 10 will automatically be entered.)",
  ],
  ["Issue #2 OOZ1ES comicbook"],
  [
    "Airdrop Series #4 3D digital toy to 30 random OOZ1ES hodlers as a unique limited edition NFT. Max supply 30. (anyone that purchases package of 10 will automatically be entered.)",
  ],
  [
    "Airdrop Series #5 3D digital toy to 30 random OOZ1ES hodlers as a unique limited edition NFT. Max supply 30. (anyone that purchases package of 10 will automatically be entered.)",
  ],
  [
    "Airdrop Series #6 3D digital toy to 30 random OOZ1ES hodlers as a unique limited edition NFT. Max supply 30. (anyone that purchases package of 10 will automatically be entered.)",
  ],
  [
    "OOZ1ES real life toy made. (Community will vote on which OOZ1ES to have made). Limited run edition.",
    "OOZ1ES plush toy made. (voted on by community)",
    "Create issue #3 of OOZ1ES comicbook",
  ],
];

const valData = [96, 92, 84, 75, 67, 58, 50, 41, 33, 16];

let splide = null;

export default function Roadmap() {
  const [range, setRange] = useState(0);
  const [img, setImg] = useState(0);
  const [open, setOpen] = useState(false);
  const [visible, setVisible] = useState(false);
  function toggleVisibility() {
    setOpen(!open);
    setVisible(!visible);
  }
  function initSplide() {
    splide = new Splide(".splide", {
      start: 0,
      arrows: false,
      direction: "ttb",
      height: 600,
      heightRatio: 0.33,
      pagination: false,
      perPage: 3,
      focus: 0,
      keyboard: false,
      trimSpace: false,
      easing: "cubic-bezier(.69,.04,.32,.97)",
    });
    splide.mount();
    splide.on("moved", function () {
      setImg(splide.index);
      setTimeout(() => {
        setRange(valData[splide.index]);
      }, 300);
    });
  }
  useEffect(() => {
    initSplide();
  }, []);
  function handleChange(e) {
    const v = e.target.value;
    let val;
    if (v >= 96) {
      val = 10;
    } else if (v >= 92) {
      val = 9;
    } else if (v >= 84) {
      val = 8;
    } else if (v >= 75) {
      val = 7;
    } else if (v >= 67) {
      val = 6;
    } else if (v >= 58) {
      val = 5;
    } else if (v >= 50) {
      val = 4;
    } else if (v >= 41) {
      val = 3;
    } else if (v >= 33) {
      val = 2;
    } else if (v >= 16) {
      val = 1;
    } else {
      val = 0;
    }
    if (splide) {
      splide.go(val);
    }
  }
  function goTo(idx) {
    if (splide) {
      setImg(idx);
      setRange(valData[idx]);
      splide.go(idx);
    } else {
      initSplide();
    }
  }
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Container text style={{ margin: "20px 0" }}>
        <Button
          fluid
          onClick={toggleVisibility}
          className={"faq-button" + (visible ? " opened" : "")}
          basic
        >
          <span>Roadmap</span>
          <div className="trw">
            <Transition
              visible={visible}
              animation="vertical flip"
              duration={200}
            >
              <Icon
                color={visible ? "green" : "black"}
                name="angle up"
                size="large"
              />
            </Transition>
          </div>
          <div className="trw">
            <Transition
              visible={!visible}
              animation="vertical flip"
              duration={200}
            >
              <Icon name="angle down" size="large" />
            </Transition>
          </div>
        </Button>
      </Container>
      <div
        className="roadmap-container"
        style={{ display: open ? "flex" : "none" }}
      >
        <div className="thermometer-container">
          <img
            src={thermometers[img]}
            alt="thermometer"
            className="thermometer"
          />
          <input
            type="range"
            min={0}
            max={100}
            step={1}
            value={range}
            onChange={handleChange}
          />
        </div>
        <div className="splide text-container">
          <div className="splide__track">
            <div className="splide__list">
              {data.map((d, i) => (
                <div
                  key={i}
                  className="splide__slide"
                  aria-hidden="true"
                  onClick={() => goTo(i)}
                  onKeyDown={() => goTo(i)}
                >
                  {d.map((item, idx) => (
                    <div key={idx}>
                      <span
                        style={{ marginBottom: 10, fontSize: 16 }}
                        role="img"
                      >
                        {"🟢"}
                      </span>{" "}
                      <span>{item}</span>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
