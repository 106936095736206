import React from "react";
import { Helmet } from "react-helmet";

export default function MetaTags() {
  return (
    <Helmet>
      <html lang="en" />
      <title>
        OOZ1ES - Kick back &amp; relax at the Ooze resort with your favorite
        #OOZ1ES
      </title>
      <link rel="icon" href="/ft/o_favicon.png" type="image/png" />
      <meta
        name="title"
        content="OOZ1ES - Kick back &amp; relax at the Ooze resort with your favorite #OOZ1ES"
      />
      <meta
        name="description"
        content="OOZ1ES were created to live forever on the Cardano Blockchain. There is a total of 8,888 unique OOZ1ES randomly generated for you to collect. No two OOZ1ES are alike, each one features 3 rarity categories: Color, Hat, and Face."
      />

      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://1.ooz1es.com/" />
      <meta
        property="og:title"
        content="OOZ1ES - A NFT on the Cardano Blockchain"
      />
      <meta
        property="og:description"
        content="OOZ1ES were created to live forever on the Cardano Blockchain. There is a total of 8,888 unique OOZ1ES randomly generated for you to collect. No two OOZ1ES are alike, each one features 3 rarity categories: Color, Hat, and Face."
      />
      <meta property="og:image" content="/ft/o_banner.jpeg" />
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content="https://1.ooz1es.com/" />
      <meta
        property="twitter:title"
        content="OOZ1ES - A NFT on the Cardano Blockchain"
      />
      <meta
        property="twitter:description"
        content="OOZ1ES were created to live forever on the Cardano Blockchain. There is a total of 8,888 unique OOZ1ES randomly generated for you to collect. No two OOZ1ES are alike, each one features 3 rarity categories: Color, Hat, and Face."
      />
      <meta property="twitter:image" content="/ft/o_banner.jpeg" />
    </Helmet>
  );
}
