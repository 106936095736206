import React from "react";

export default function About() {
  return (
    <>
      <h1 className="section-header">About</h1>
      <p className="details">
        OOZ1ES were created to live forever on the Cardano Blockchain. There is
        a total of<span className="pink"> 8,888 </span>unique OOZ1ES randomly
        generated for you to collect. No two OOZ1ES are alike, each one features
        <span className="pink"> 3 </span> rarity categories: Body color, Hat,
        and Face.
      </p>
      <p className="details">
        Body colors =<span className="pink"> 52</span>, Hat variations =
        <span className="pink"> 49</span>, Face variations =
        <span className="pink"> 17 </span>for a total of
        <span className="pink"> 118 </span>total variations!
      </p>
      <p className="details">
        In the near future OOZ1ES will be featured in comic books &amp;
        collectible toys from <a href="https://anti.biz">Anti.biz</a>
      </p>
      <p className="details">
        If you like the stuff <a href="https://anti.biz">Anti.biz</a> creates
        consider staking with our stake pool @{" "}
        <a href="https://www.anti.biz/cardano">www.anti.biz/cardano</a> we will
        always be a single pool &amp; we donate to good causes as well!~
      </p>
    </>
  );
}
