import React, { useState } from "react";
import { Accordion, Button, Icon, Transition } from "semantic-ui-react";

const data = [
  {
    title: "How can I buy an OOZ1ES?",
    description:
      'You can buy an OOZ1ES by clicking the BUY NOW button. This will bring up a pop-up that will display a Cardano payment address.<br />There will also be information on how much<span class="pink"> ₳ADA </span>to send. For example<span class="pink"> 4 ₳ADA </span>will get you <span class="pink">1</span> OOZIES. There will be discounts if you buy in bulk. <br/><br/>You will need a Cardano wallet like <a href="https://daedaluswallet.io/">Daeladus</a> (full node), <a href="https://yoroi-wallet.com/#/">Yoroi</a> (light wallet), or <a href="https://chrome.google.com/webstore/detail/nami-wallet/lpfcbjknijpeeillifnkikgncikgfhdo">Nami</a> (light wallet, no hardware wallet support).<br/><br/>Inside of your Cardano wallet you will need some Cardano also known as<span class="pink"> ₳ADA </span>. If you live inside the USA you can buy some Cardano with a debit card or bank account @ <a href="https://www.bittrex.com">www.bittrex.com</a> or <a href="https://www.crypto.com">www.crypto.com</a> (great app which makes buying and sending<span class="pink"> ₳ADA </span>easy)<br/><br/><span style="color:#ff143a;">Do Not send<span class="pink"> ₳ADA </span>from an exchange, you will not receive your NFT there, make sure you\'re using one of the wallets above.</span><br/><br/>For those outside of the USA you should be able to buy<span class="pink"> ₳ADA </span>(Cardano) @ <a href="https://www.binance.com">www.binance.com</a>',
  },
  {
    title: "What is a NFT?",
    description:
      'NFT is a Non Fungible Token which makes it unique and can\'t be replaced with something else. Think of these like a one-of-akind trading card. You will be able to trade, buy and sell these after you get 1.<br/><br/>These NFTS will be minted on the Cardano blockchain as<span class="pink"> ₳ADA </span>is sent to the address. They will be distributed automatically in a fair random order. Which means that everyone has an equal chance of getting a rare OOZ1ES!<br/><br/>These OOZ1ES will be minted using a time-locked policy, meaning that no more OOZ1ES can be created (minted) or destroyed (burned) after a certain amount of time.<br/><br/>Therefore, by definition, all OOZ1ES are NFTs.<br/><br/>You can view the minting policy <a href="https://cardanoscan.io/tokenPolicy/1ec19c1629869bffd649e748e29bc81c951de172b1b54217138b35c9">here</a>.',
  },
  {
    title: "What is metadata?",
    description:
      'The metadata for each OOZ1ES is on-chain. What that means is that the Cardano blockchain stores that information of each NFT forever. Each NFT will store the description, image, policy #, links and unique traits of each OOZ1ES. The metadata is created when you purchase an OOZ1ES from our website <a href="https://1.ooz1es.com">1.ooz1es.com</a> automatically and sent to your wallet!',
  },
  {
    title: "What is a policy number?",
    description:
      'A single policy number for all <span class="pink">8,888</span> OOZ1ES will be created during the minting of each NFT. This is a way to know if you got an authentic OOZ1ES or someone is creating a knock-off or imitation. After the sale is over we will verify our policy # on <a href="https://cnft.io">CNFT.IO</a> please wait to verify the policy # before buying or selling. It could take a few days or more depending on how long the admins of <a href="https://cnft.io">CNFT.IO</a> take to verify our policy number! ',
  },
  {
    title: 'What does "Minting" mean?',
    description:
      "Minting means to create a NFT (Non Fungible Token or Fungible Token) on the Cardano blockchain. You can think of minting as it being created. As the OOZ1ES NFT doesn't exist till it’s minted or created.  When you buy a OOZ1ES it is minted/created on the spot, so that it now permanently exist on the Cardano block chain as metadata. There is a time-locked policy which will lock at a certain time, which will make it impossible for anymore OOZ1ES to be minted (created) or burned (destroyed) after that set time, <u>9/21/2022 11:59 PM</u>. Since all of the OOZ1ES are minted (created) using the same time-locked policy this increases their value as a collectibles. You can think of this like a set of Pokémon characters that were created, but no more will be created like them ever again.",
  },
  {
    title: "How do I view my NFT?",
    description:
      'To view your NFT you can use a Cardano wallet like <a href="https://chrome.google.com/webstore/detail/nami-wallet/lpfcbjknijpeeillifnkikgncikgfhdo">NAMI</a><br/>The easiest way is to input your Cardano receive address into the search bar @ <a href="https://www.pool.pm">www.pool.pm</a> click the magnifying glass in the top right corner and add your address where you received the NFTS at. You will then be able to see your OOZ1ES NFTS (image, metadata)<br/>',
  },
  {
    title: "Are OOZ1ES a good investment?",
    description:
      'That is ultimately a decision for you to make. We believe OOZ1ES have a long life ahead of them, and will be an ever growing and evolving project. (On & off the blockchain) However the success of OOZ1ES relies on so many factors and variables, no one knows! Hopefully OOZ1ES go to the moon, but like anything in life, don’t spend money you can’t afford to not have.<br/><br/>OOZ1ES will soon be a registered trademark of <a href="https://anti.biz" target="_blank" rel="noreferrer">Anti.biz</a> and is integral part of the universe / lore of <a href="https://anti.biz" target="_blank" rel="noreferrer">Anti.biz</a> (comic books, toys, games, etc)<br/><br/>NFTs on Cardano is the first step in a long road for building the world of OOZ1ES. If you like the ideas and want to support the project we appreciate every that does. We promise to keep building this world for years and years to come. With every NFT you purchase you are helping us build this universe a lot faster then we could do on our own. In time with everyone’s support it truly will become something epic!',
  },
  {
    title: "What special perks do I get for hodling your NFT?",
    description:
      'Everyone that holds an NFT created by <a href="https://anti.biz" target="_blank" rel="noreferrer">Anti.biz</a> will be recognize as part of the community and receive exclusive access to private content/products and receive discounts towards new things we create!',
  },
  {
    title: "Who is the team that created OOZ1ES?",
    description:
      'The team is made up of the creator <a href="https://www.twitter.com/diicasses" target="_blank" rel="noreferrer">@diicasses</a>, artist  <a href="https://www.twitter.com/cryptomuseumnft" target="_blank" rel="noreferrer">@cryptomuseumnft</a>, coding <a href="#">peanut#1175</a> (Discord), <a href="https://easycnft.art/" target="_blank" rel="noreferrer">backend sales</a>, and the <a id="wd" target="_blank" rel="noreferrer" href="https://wa.me/+8801861590250">web developer</a>. Which is all a part of the business <a href="https://anti.biz" target="_blank" rel="noreferrer">Anti.biz</a>',
  },
  {
    title: "What can I do with my OOZ1ES?",
    description:
      "You are free to do anything with them under a non-exclusive license.",
  },
  {
    title: "How do I get involved?",
    description:
      'Head on over to <a href="https://discord.gg/P8wQgEWn2t" target="_blank"  rel="noreferrer">Discord</a> & <a href="https://www.twitter.com/ooz1es" target="_blank"  rel="noreferrer">Twitter</a> to jump into the conversation & share your ideas!~',
  },
];

export default function FAQ() {
  const [visible, setVisible] = useState(false);
  const [activeIndex, setActiveIndex] = useState(-1);

  function toggleVisibility() {
    setVisible((v) => !v);
  }

  function handleClick(_, titleProps) {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index;
    setActiveIndex(newIndex);
  }

  return (
    <div>
      <Button
        fluid
        onClick={toggleVisibility}
        className={"faq-button" + (visible ? " opened" : "")}
        basic
      >
        <span>FAQ</span>
        <div className="trw">
          <Transition
            visible={visible}
            animation="vertical flip"
            duration={200}
          >
            <Icon
              color={visible ? "green" : "black"}
              name="angle up"
              size="large"
            />
          </Transition>
        </div>
        <div className="trw">
          <Transition
            visible={!visible}
            animation="vertical flip"
            duration={200}
          >
            <Icon name="angle down" size="large" />
          </Transition>
        </div>
      </Button>
      <Transition
        visible={visible}
        animation="slide down"
        duration={400}
        unmountOnHide
      >
        <Accordion styled fluid>
          {data.map((d, idx) => (
            <React.Fragment key={idx}>
              <Accordion.Title
                active={activeIndex === idx}
                index={idx}
                onClick={handleClick}
                style={{ fontSize: 24, color: "#444" }}
              >
                <Icon name="dropdown" />
                {d.title}
              </Accordion.Title>
              <Accordion.Content active={activeIndex === idx}>
                <Transition.Group animation="slide down" duration={300}>
                  {activeIndex === idx && (
                    <p
                      style={{ fontSize: 22 }}
                      dangerouslySetInnerHTML={{ __html: d.description }}
                    />
                  )}
                </Transition.Group>
              </Accordion.Content>
            </React.Fragment>
          ))}
        </Accordion>
      </Transition>
    </div>
  );
}
