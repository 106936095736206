import React, { useEffect, useRef } from "react";
import Typed from "typed.js";
import Rotate3D from "./Rotate3D";
import banner from "../images/o_banner.png";
import logo from "../images/o_logo_3d.png";

export default function Header({ onBuyButtonClicked }) {
  const text = useRef(null);
  const typed = useRef(null);
  useEffect(() => {
    typed.current = new Typed(text.current, {
      strings: [
        'From the creators of <span class="break"></span><a href="https://spacebabez.io" id="sb" target="_blank" rel="noreferrer">Space Babez</a> <span class="break"></span>&amp;<span class="break"></span> <a href="https://swingerz.io" id="sw" target="_blank" rel="noreferrer">Swingerz</a>',
      ],
      typeSpeed: 85,
      backSpeed: 35,
      backDelay: 0,
      showCursor: false,
    });
    return () => {
      typed.current?.destroy();
    };
  }, []);
  return (
    <div className="main-header">
      <img src={banner} className="banner" alt="OOZIES Banner" />
      <h1 className="mt-0 typed-slogan" aria-hidden="true" ref={text} />
      <div className="header-container">
        <div className="header-left"></div>
        <div className="header-middle">
          <img src={logo} className="logo" alt="OOZIES Logo" />
          <h1>
            Kick back &amp; relax at the Ooze resort with your favorite
            <span className="hashtag"> #OOZ1ES</span>
          </h1>
          <Rotate3D />
          <button className="buyNow" onClick={onBuyButtonClicked}>
            {" "}
          </button>
          <h1>
            <span className="pink">8,888</span> crypto collectibles on the
            Cardano blockchain
          </h1>
        </div>
        <div className="header-right"></div>
      </div>
    </div>
  );
}
