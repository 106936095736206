import React from "react";
import { Dropdown } from "semantic-ui-react";

const data = Array.from({ length: 9999 }).map((_, i) => ({
  text: "OOZ1ES#" + (i + 1),
  key: i,
  value: i,
}));

const SearchInput = ({ selectedOptions = [], onSelected }) => {
  return (
    <Dropdown
      placeholder="Search by Name"
      fluid
      multiple
      selection
      clearable
      lazyLoad
      search
      basic
      options={data}
      value={selectedOptions}
      onChange={onSelected}
      style={{ marginBottom: 15, minHeight: 51 }}
    />
  );
};

export default SearchInput;
