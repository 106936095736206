import React, { useState, useEffect, useRef } from "react";
import { Button, Container, Icon } from "semantic-ui-react";
import Header from "../components/Header";
import Faq from "../components/FAQ";
import BodyRarity from "../components/BodyColorRarity";
import HatRarity from "../components/HatRarity";
import FaceRarity from "../components/FaceRarity";
import SearchInput from "../components/SearchInput";
// import RarityButton from "../components/RarityButton";
import About from "../components/About";
import PreviewModal from "../components/PreviewModal";
import ImageButton from "../components/ImageButton";
import BuyModal from "../components/BuyModal";
import MetaTags from "../components/MetaTags";
import Roadmap from "../components/Roadmap";
import data from "../data/oozies.json";
import bodies from "../data/ooziesBody.json";
import hats from "../data/ooziesHat.json";
import faces from "../data/ooziesFace.json";
import ranks from "../data/ooziesRank.json";
import bodyCats from "../data/bodyCategory.json";
import hatCats from "../data/hatCategory.json";
import faceCats from "../data/faceCategory.json";
import ExternalLinks from "../components/ExternalLinks";

const topButtons = [
  { label: "All", value: 0 },
  { label: "Top 10", value: 10 },
  { label: "Top 25", value: 25 },
  { label: "Top 50", value: 50 },
  { label: "Top 100", value: 100 },
];

let prevY = 50;

const IndexPage = () => {
  const [max, setMax] = useState(50);
  const [filteredIds, setFilteredIds] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedBody, setSelectedBody] = useState("");
  const [selectedHat, setSelectedHat] = useState("");
  const [selectedFace, setSelectedFace] = useState("");
  const [selectedRank, setSelectedRank] = useState(0);
  const [openedBuyModal, setOpenedBuyModal] = useState(null);
  const [openedImage, setOpenedImage] = useState(null);
  const loadingRef = useRef();

  function onImageModalOpen(val) {
    setOpenedImage(val);
  }

  function onImageModalClose() {
    setOpenedImage(null);
  }

  useEffect(() => {
    function handleObserver(entities) {
      const y = entities[0].boundingClientRect.y;
      if (prevY >= y) {
        setMax((i) => Math.min(i + 20, 9999));
      }
      prevY = y;
    }
    const observer = new IntersectionObserver(handleObserver, {
      root: null,
      rootMargin: "0px",
      threshold: 1.0,
    });
    observer.observe(loadingRef.current);
    const styles =
      "color: yellow;background: black;font-size: 18px;border-left: 1px solid red;border-right: 1px solid red;padding: 10px";
    console.log(
      "%cHi Stranger!                          \n" +
        "Creator: https://twitter.com/diicasses\n" +
        "Web Dev: https://wa.me/+8801861590250 ",
      styles
    );
  }, []);

  useEffect(() => {
    if (selectedRank > 0) {
      setMax(100);
      return;
    }
    setMax((m) => (m > filteredIds.length ? filteredIds.length : m));
  }, [selectedRank, filteredIds]);

  useEffect(() => {
    if (
      selectedIds.length === 0 &&
      !selectedBody &&
      !selectedHat &&
      !selectedFace
    ) {
      if (selectedRank > 0) {
        setFilteredIds(ranks.slice(0, selectedRank));
      } else {
        setMax(50);
        setFilteredIds(Array.from({ length: 9999 }, (_, i) => i));
      }
      return;
    }
    let allIds = [];
    if (selectedIds.length > 0) {
      allIds = selectedIds;
    }
    if (selectedBody !== "") {
      const selects = bodyCats[selectedBody] || bodies[selectedBody];
      if (allIds.length === 0) {
        allIds = selects;
      } else {
        allIds = allIds.filter((id) => selects.indexOf(id) !== -1);
      }
    }
    if (selectedHat !== "") {
      const selects = hatCats[selectedHat] || hats[selectedHat];
      if (allIds.length === 0) {
        allIds = selects;
      } else {
        allIds = allIds.filter((id) => selects.indexOf(id) !== -1);
      }
    }
    if (selectedFace !== "") {
      const selects = faceCats[selectedFace] || faces[selectedFace];
      if (allIds.length === 0) {
        allIds = selects;
      } else {
        allIds = allIds.filter((id) => selects.indexOf(id) !== -1);
      }
    }
    if (allIds.length > 0) {
      setMax(50);
      setSelectedRank(0);
    }
    setFilteredIds(allIds);
  }, [selectedIds, selectedBody, selectedHat, selectedFace, selectedRank]);

  function handleIdsChange(_, ids) {
    setSelectedIds(ids.value);
  }
  function handleBodyChange(_, body) {
    if (body.value && body.value.toString().length > 0)
      setSelectedBody(body.value);
    else setSelectedBody("");
  }
  function handleHatChange(_, hat) {
    if (hat.value && hat.value.toString().length > 0) setSelectedHat(hat.value);
    else setSelectedHat("");
  }
  function handleFaceChange(_, face) {
    if (face.value && face.value.toString().length > 0)
      setSelectedFace(face.value);
    else setSelectedFace("");
  }
  function handleRankChange(rank) {
    setSelectedIds([]);
    setSelectedBody("");
    setSelectedHat("");
    setSelectedFace("");
    setSelectedRank(rank);
  }

  return (
    <>
      <MetaTags />
      <Header onBuyButtonClicked={() => setOpenedBuyModal(true)} />
      <div className="socials">
        <a href="https://www.twitter.com/ooz1es">
          <Icon name="twitter" />
        </a>
        <a href="https://discord.gg/P8wQgEWn2t">
          <Icon name="discord" />
        </a>
        <a href="https://www.instagram.com/ooz1es">
          <Icon name="instagram" />
        </a>
        <a href="https://www.facebook.com/ooz1es">
          <Icon name="facebook" />
        </a>
      </div>
      <Container text style={{ marginTop: 20 }}>
        <About />
        <a
          href="https://cardanoscan.io/tokenPolicy/1ec19c1629869bffd649e748e29bc81c951de172b1b54217138b35c9"
          className="policy"
        >
          <strong>Policy ID</strong>
          <p>1ec19c1629869bffd649e748e29bc81c951de172b1b54217138b35c9</p>
        </a>
        <Faq />
        <ExternalLinks />
      </Container>
      <Roadmap />
      <Container text style={{ marginTop: 20 }}>
        {/* <RarityButton /> */}
        <h1 className="section-header">Filters</h1>
        <SearchInput
          selectedOptions={selectedIds}
          onSelected={handleIdsChange}
        />
        <BodyRarity rarity={selectedBody} onRarityChanged={handleBodyChange} />
        <HatRarity rarity={selectedHat} onRarityChanged={handleHatChange} />
        <FaceRarity rarity={selectedFace} onRarityChanged={handleFaceChange} />
        <div className="top-filter">
          {topButtons.map((btn) => (
            <Button
              key={btn.value}
              className={selectedRank === btn.value ? "activeBtn" : null}
              onClick={() => handleRankChange(btn.value)}
            >
              {btn.label}
            </Button>
          ))}
        </div>

        <p className="results">{filteredIds.length} results</p>
      </Container>
      <div className="resultContainer">
        <div className="imgContainer">
          {filteredIds.slice(0, max).map((img) => (
            <ImageButton
              key={img}
              value={img}
              onClick={() => onImageModalOpen(data[img])}
            />
          ))}
        </div>
      </div>
      <div
        ref={loadingRef}
        className="ui active centered inline loader"
        style={{
          display:
            filteredIds.length === 0 || filteredIds.length < max
              ? "none"
              : "block",
        }}
      ></div>
      {openedImage ? (
        <PreviewModal
          openedImage={openedImage}
          onImageModalClose={onImageModalClose}
        />
      ) : (
        <BuyModal
          open={openedBuyModal}
          onClose={() => setOpenedBuyModal(false)}
        />
      )}
      {/* <AudioPlayer /> */}
    </>
  );
};

export default IndexPage;
